.home {
    width: 100%;
    /* background-color: rgb(177, 164, 164); */
    /* background-color: rgb(51, 48, 48); */
    /* background: rgb(168,170,181);
    background: radial-gradient(circle, rgba(168,170,181,1) 0%, rgba(42,36,37,1) 100%); */
    /* background: rgb(127,118,133);
    background: linear-gradient(90deg, rgba(127,118,133,1) 0%, rgba(47,39,39,1) 50%, rgba(127,118,133,1) 100%); */
    /* 
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(186,186,212,1) 15%, rgba(186,186,212,1) 85%, rgba(2,0,36,1) 100%); */
}

.full-the-bottom {
    min-height: 30vh;

}


.about_me_container>* {
    color: white;
}

.react-slideshow-container {

    max-width: 50rem;
    margin: auto;


}

.react-slideshow-container .default-nav {

    height: 30px;
    width: 25px;
    background-color: rgba(246, 238, 238, 0.30);
    position: relative;
}

.react-slideshow-container .default-nav:last-of-type {
    right: 0.2rem;
}


.react-slideshow-container .default-nav:first-of-type {
    left: 0.2rem;
}




.react-slideshow-container .default-nav svg {
    width: 18px;
    height: 18px;
    fill: #000000;



}

.aboutMeSection {
    height: auto;
    display: flex;
    flex-direction: row;
    margin: 14vw auto auto auto;
    width: 100%;
    /* padding: 2rem; */
    align-items: center;
}

.about_global_container {
    display: flex;
    width: 85%;
    margin: auto;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}



.about_me_container {
    height: 100%;
    /* width: 47%; */
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: space-between;
    position: relative;
}

.about_me_container div:first-of-type {
    max-width: 40rem;
    /* padding-left: 3rem; */
}

.about_me_container>* {
    background-color: transparent;
}

.about_me_container:first-child {
    text-align: right;
    max-width: 50rem;
}

.about_me_container:last-child {
    max-width: 40rem;
}



.about_me_container h3 {
    max-width: 33rem;
    text-align: left;
    margin-top: 2rem;
    margin-bottom: 3rem;
    font-size: 3.3rem;
    background: linear-gradient(to right, wheat, rgb(240, 236, 236), wheat);
    background-clip: text;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    font-family: "Italianno", cursive;
    letter-spacing: 2px;

}


/* Chrome version 29 and above */


.about_me_container p {
    text-align: left;
    font-size: clamp(1.3rem, 2vw, 1.7rem);
    font-style: italic;
    line-height: 2.3rem;
    margin-bottom: 2rem;
    letter-spacing: 0.4px;
    position: relative;
    bottom: 2px;

}

.about_me_container strong {
    text-decoration: underline;
    text-underline-offset: 5px;
}


.list_tag_container {
    margin: 5rem 0 2rem 0;
}

.list_tag {
    display: flex;
    flex-direction: row;
}

.list_tag_icon {
    width: 2rem;
    color: wheat;
    font-size: 2rem;
    border: 1px solid wheat;
    border-radius: 99999px;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
}

.list_tag_icon::before {
    content: "\203A";
    transform: translate(50%, -50%);
    display: flex;
    height: 2rem;
    width: 2rem;
    bottom: -0.4rem;
    position: relative;
    right: 0.25rem;


}

.about_me_container img {
    bottom: 2.5rem;
    height: 56%;
    width: 60%;
    position: relative;
    border: 4px inset wheat;
    left: -1rem;
    margin-left: 1rem;
    border-radius: 1%;
    margin-bottom: 0rem;
    box-shadow: 0px 1px 5px 3px #ffffff;
}



@media screen and (min-width: 385px) {
    .about_me_container img {
        bottom: 17.5rem;
        height: 56%;
        width: 49%;
        position: relative;
        border: 4px inset wheat;
        /* margin: auto; */
        left: 19rem;
        margin-left: 1rem;
        border-radius: 4%;
        margin-bottom: -12rem;
        box-shadow: 0px 1px 5px 3px #ffffff;
        max-height: 30.8rem;
    }

}





@media (min-width: 0px) and (max-width: 700px) {

    .no-display-xs-to-m {
        display: none;
    }


}



@media screen and (min-width: 607px) {


    .aboutMeSection {
        margin: 8vw auto -10rem auto;
    }

    .about_me_container div:first-of-type {
        max-width: 40rem;
        position: relative;
        right: 5rem;
    }

    .about_me_container img {
        bottom: 31rem;
        width: 65%;
        margin: auto;
        left: 20rem;
        border-radius: 0%;
    }
}

@media screen and (min-width: 850px) {

    .aboutMeSection {
        margin: 14vw auto -22rem auto;
    }


    .about_me_container div:first-of-type {
        right: 0rem;
    }

    .about_me_container {
        margin: 0 0 0 5rem;
    }

    .about_me_container img {
        bottom: 35rem;
        width: 48%;
        left: 33rem;
        max-height: 50rem;
    }



}



@media screen and (min-width: 1000px) {

    .aboutMeSection {
        margin: 2vw auto 0rem auto;
        padding-top: 9vh;
        padding-bottom: 6vh;
    }


    .about_me_container img {
        bottom: 38rem;
        height: 56%;
        width: 63%;
        position: relative;
        border: 5px inset wheat;
        margin: auto;
        left: 52rem;
        margin-left: 1rem;
        border-radius: 0%;
        margin-bottom: -28rem;
        box-shadow: 0px 1px 5px 3px #ffffff;
        max-height: 50rem;
    }



}


@media screen and (min-width: 1200px) {



    .about_me_container img {
        bottom: 35rem;
        height: 56%;
        width: 84%;
        position: relative;
        border: 5px inset wheat;
        left: 100%;
        margin-left: 12rem;
        border-radius: 0%;
        box-shadow: 0px 1px 5px 3px #ffffff;
        max-height: 50rem;
    }



}

.slick-arrow {
    display: none !important;
}

@media (min-width: 1500px) {
    .about_global_container {
      width: 63% !important;
      
    }
}


@media (min-width: 1670px) {
    .about_global_container {
      width: 50% !important;
      
    }
}

@media (min-width: 2400px) {
    .about_global_container {
      width: 35% !important;
    }
}