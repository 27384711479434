@import url("https://fonts.googleapis.com/css2?family=Nova+Mono&display=swap");

/* Font : Nova Mano */

/* 
.portfolio_card:nth-child(7) .btn_frame_left {
    display: none;
} */

.invisible {
  opacity: 0;
}

.portfolio_card {
  /* width: 44%;
    height: 84rem; */
  /* width: 37rem;
    height: 63rem; */
  width: 56rem;
  height: 68rem;
  margin: 14rem auto 0 auto;
  border: thick double white;
  border-radius: 10px 10px 3px 3px;
  background-color: white;
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 2px 1px 8px rgb(255, 237, 202), -2px -1px 8px white;
  max-width: 90%;
}

.portfolio_card.invisible {
  margin: 0;
}

/* .portfolio_card:last-child {
  position: relative;
} */

.portfolio_card:hover .portfolio_card_line {
  border: 2px solid black;
}

.portfolio_card_line {
  width: 90%;
  margin: auto;
  border: 1.5px solid rgb(0, 0, 0);
  background-color: black;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.portfolio_card_line_bottom {
  position: absolute;
  bottom: 9rem;
  left: 0;
  right: 0;
  margin: auto;
}

.portfolio_card_img {
  width: 100%;
  height: 60rem;
}

.portfolio_card_img_element {
  width: 98%;
  height: 50rem;
  border-radius: 10px 10px 3px 3px;
  margin: 0.7rem auto;
  transition: height 0.3s ease-in-out, width 0.3s ease-in-out;
  border: 1px solid black;
  padding: 3px;
}

.portfolio_card_description {
  width: 100%;
  height: auto;
}

.portfolio_card_description_title {
  font-size: 2.4rem;
  color: black;
  margin: 2rem auto 0.8rem 3rem;
  font-family: "Nova Mono", monospace;
  transition: font-size 0.3s ease-in-out;
  text-shadow: 0.5px 0.5px 2px black;
}

.portfolio_card_description_text {
  margin-top: 1.5rem;
  font-size: 1.8rem;
  font-style: italic;
  transition: all 0.2s ease-in-out;
  text-align: left;
  padding-left: 5rem;
  margin-right: 3rem;
}

.protfolio_card_description_text_added {
  font-size: 1.8rem;
  font-style: italic;
  transition: all 0.2s ease-in-out;
  text-align: left;
  padding-left: 5rem;
  margin-top: 0.5rem;
  margin-right: 3rem;
}

.portfolio_card_link {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

.btn_frame {
  display: inline-block;
  border: 2px solid silver;
  border-radius: 5px;
  font-size: 2rem;
  position: absolute;
  bottom: 2rem;
  padding: 1rem;
  width: calc(10rem + 5%);
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  transition: font-weight 0.2s ease-in-out, padding 0.4s ease-in-out,
    width 0.4s ease-in-out, margin-left 0.4s ease-in-out, color 0.3s ease-in-out,
    background-color 0.5s ease-in-out;
  border: 2px solid black;
}

.btn_frame:hover {
  background-color: black;
  color: #babad4;
  font-weight: 300;
}

.btn_frame_left {
  left: 8rem;
}

.btn_frame_right {
  right: 8rem;
}

@media (max-width: 1100px) {
  .portfolio_card.invisible.zero {
    display: none;
  }
}

@media (min-width: 1150px) and (max-width: 1380px) {
  .portfolio_card.invisible.zero {
    display: flex;
  }
}

@media (min-width: 1500px) {
  .portfolio_card.invisible.zero {
    display: none;
  }
}

@media (min-width: 1700px) {
  .portfolio_card.invisible.first {
    display: none;
  }
}

@media (max-width: 1224px) {
  .portfolio_card.invisible.third {
    display: none;
  }
}

@media (max-width: 1400px) {
  .portfolio_card.invisible.first,
  .portfolio_card.invisible.third {
    display: none;
  }
}

@media (max-width: 2230px) {
  .portfolio_card.invisible.second,
  .portfolio_card.invisible.third {
    display: none;
  }
}

@media (min-width: 1800px) {
  .portfolio_card.invisible.third {
    display: none;
  }
}

@media (min-width: 2220px) {
  .portfolio_card.invisible.zero {
    display: flex;
  }
}

@media (max-width: 2220px) {
  .portfolio_card.invisible.five
 {
    display: none;
  }
}

@media (max-width: 3200px) {
  .portfolio_card.invisible.six {
    display: none;
  }
}

@media (min-width: 2450px) {
  .portfolio_card.invisible.six  {
    display: flex;
  }
}

@media (min-width: 2220px) {
  .portfolio_card.invisible.five  {
    display: flex;
  }
}



@media (min-width: 2450px) {
  .portfolio_card.invisible.first,
  .portfolio_card.invisible.second,
  .portfolio_card.invisible.third {
    display: none !important;
  }
}

@media (max-width: 1700px) {
  .portfolio_card {
    width: 56rem;
    max-width: 90%;
  }

  .portfolio_card_img_element {
    height: 40rem;
  }

  .portfolio_card_description_text {
    font-size: 1.5rem;
  }

  .protfolio_card_description_text_added {
    font-size: 1.5rem;
  }

  .btn_frame_left {
    left: 4rem;
  }

  .btn_frame_right {
    right: 4rem;
  }
}

@media (max-width: 1400px) {
  .portfolio_card {
    margin-bottom: 10rem;
    margin-top: 4rem;
  }
}

@media (max-width: 800px) {
}

@media (max-width: 750px) {
  .invisible {
    display: none;
  }
}

@media (max-width: 550px) {
  .invisible {
    display: none;
  }

  .portfolio {
    width: 100%;
  }

  .btn_frame {
    bottom: 2rem;
    padding: 1rem;
    width: calc(8rem + 5%);
    font-size: 2rem;
  }

  .btn_frame:hover {
    background-color: black;
    color: #babad4;
    font-weight: 300;
  }

  .btn_frame_left {
    left: 2rem;
  }

  .btn_frame_right {
    right: 2rem;
  }
}

@media (max-width: 400px) {
  .invisible {
    display: none;
  }
}

@media (min-width: 1700px) {
  .portfolio {
    display: flex;
    justify-content: center;
  }
  .portfolio_card {
    height: 78rem !important;
  }
}

@media (min-width: 1600px) {
  .portfolio_card.invisible.third {
    display: none !important;
  }
}

@media (min-width: 1100px) {
  .portfolio {
    justify-content: space-around;
  }

  .portfolio_card {
    max-width: 50rem;
  }
}

@media (max-width: 1000px) {
  .portfolio {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 700px) {
  .portfolio_card {
    min-height: 74rem;
    margin: 2rem auto;
  }
}

@media (max-width: 450px) {
  .portfolio {
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .portfolio_card {
    max-width: 32rem;
    margin: 2rem auto;
  }
}

@media (max-width: 380px) {
  .portfolio_card {
    max-width: 30em;
    margin: 2rem auto;
  }
}
