@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;

}


*, ::before, ::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none; 
  color: inherit;
}


html {
  font-size: 62.5%;
}

body {
  background-color: black;
  /* background: #141313; */
  /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(186,186,212,1) 10%, rgba(186,186,212,1) 90%, rgba(2,0,36,1) 100%); */
  min-height: 100vh;
  height: 100%; 
  margin: 0;
  padding: 0;
}








@media screen and (max-width: 800px) {
    body {
        background: linear-gradient(90deg, rgb(240, 240, 245) 0%, black 2%, black 98%, rgb(255, 255, 255) 100%);
        
    }
}


