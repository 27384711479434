@import url('https://fonts.googleapis.com/css2?family=Arima+Madurai&display=swap');
/* font-family: 'Arima Madurai', cursive; Police tittle  */

@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;700&display=swap');
/* font-family: 'Montserrat Alternates', sans-serif; Font card title  */

@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai&display=swap');
/* Fond card text */ 



.journal {
    width: 100%;
    height: 150rem;
    
    
}

.journal * {
    color: white;
    
}

.journal_header_title {
    font-family: 'Arima Madurai', cursive;
    font-size: 3.5rem;
    margin-top: 8rem;
    margin-bottom: 5rem;
    font-style: italic;
    color: wheat;

}


.main_journal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 60%;
    height: auto;
    margin: auto;
    position: relative;
}



.actuality_container {
    width: 100%;
    display: flex;
    position: relative;
    height: 20rem;
    justify-content: center;
    margin: auto;
    
    
}

.actuality_container::before {
    display: flex;
    content: " ";
    height: 3px;
    position: absolute;
    width: 100%;
    top: 24rem;
    margin: auto;
    background-color: rgb(255, 255, 255);
}

.actuality_card {
    display: flex;
    max-width: 120rem;
    min-width: 40rem;
    width: 40%;
    height: 10em;
    margin: 4rem auto 0 auto;
    padding: 6rem 0;
    background-color: black;
    /* background-color: rgb(248, 233, 205); */
    flex-direction: column;
    border-radius: 15px;
    left: 4%;
    border: 2px solid white;
    align-items: center;
    justify-content: center;

}

.actuality_card_title {
    color: white;
    display: flex;
    margin: 0 auto 2rem auto;
    bottom: 5rem;
    font-size: 2rem;
}

.actuality_card_text {
    margin-left: 3rem;
    display: flex;
    font-size: 1.6rem;
    color: white;
    justify-content: center;
    font-weight: 700;
}


.journal_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 7rem auto 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
}


.journal_container_title {
    font-size: 3.5rem;
    color: wheat;
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 10rem;
    width: 100%;
}


.journal_card_container {
    display: flex;
    flex-direction: row;
    margin: 5rem auto 0 auto;
    justify-content: space-around;
    flex-wrap: wrap;
    
}

.journal_card {
    display: flex;
    width: 45%;
    min-width: 30rem;
    max-width: 60rem;
    min-width: 50rem;
    flex-direction: column;
    border: 2px outset rgb(0, 0, 0);
    border-radius: 3px;
    padding: 2rem;
    margin-bottom: 5rem;
    height: 70rem;
    position: relative;
    background-color: rgb(255, 255, 255);
}


.journal_card:before {
    content: " ";
    position: absolute;
    z-index: 5;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    border: 3px outset #000000;
  }

/* .journal_card::after {
    content: " ";
    position: absolute;
    z-index: 5;
    top: -3px;
    left: -3px;
    right: 5px;
    bottom: 5px;
    border-left: 5px outset rgb(255, 253, 250);
    border-top: 5px outset rgb(255, 255, 255);
} */

.journal_card_title {
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 2.2rem;
    margin: auto auto 2rem auto;
    border-bottom: 1px solid rgb(0, 0, 0);
}

.journal_card_abstract {
    font-style: italic;
}

.journal_card > * {
    margin-top: 2rem;
    width: auto;
    /* color: rgb(252, 235, 203); */
    color: black;
    text-align: left;
    font-size: 1.8rem;
    line-height: 3rem;
    word-spacing: 0.3rem;
    font-family: 'Hind Madurai', sans-serif;
}

.journal_card_date {
    text-align: center!important;
    margin-top: 0!important;
    margin-bottom: 1rem;
    font-weight: 800;
}


.journal_card p {
    font-weight: 700;
}

br {
    margin: 1rem 0;
}



@media (max-width: 2334px) {

    .journal {
        height: auto;
    }

    .actuality_container {
        justify-content: center;
    }

    .actuality_card {
        text-align: center;
        display: flex;
        width: 45%;
        align-items: center;
        left: auto;
    }

    .actuality_card_text {
        margin-left: 0;
    }

    .journal_container {
        margin-top: 12rem;

    }

}

@media (max-width: 1500px) {

    .journal {
        height: auto;
        width: auto;
    }

    .journal_card {
        height: auto;
    }


}



@media (max-width: 700px) {

    .actuality_card {
        width: 80%;
        min-width: 30rem;
    }


    .actuality_container::before {
        width: 85%;
    }

    .main_journal {
        width: 100%;


    }

    .journal_card {
        width: 100%;
        min-width: 20rem;
    }

}

@media (max-width: 550px) {

    .journal_card {
        margin: 4rem 5%;
    }

}


@media (max-width: 400px) {

    .actuality_card_text {
        font-size: 1.4rem;
    }


    
    
    .main_journal {
        margin-bottom: 5rem;
    }
    
}