.portfolio {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 95%;
  margin: 0rem auto 0 auto;
  position: relative;
  gap: 1rem 4rem;
  padding-bottom: 8rem;
}

.portfolio_header_title {
  color: wheat;
  text-align: center;
  width: 100%;
  margin: 8rem auto 5rem auto;
  font-size: 3.5rem;
  /* font-family: 'Arima Madurai', cursive; */
  font-family: "Italianno", cursive;
  font-style: italic;
  letter-spacing: 0.1rem;
  word-spacing: 0.4rem;
  text-decoration: underline;
  text-underline-offset: 8px;
}

.portfolio_card:nth-child(2n + 1)::before {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  border: 0.5px solid transparent;
  border-right: none;
  border-top: none;
  bottom: -2.5rem;
  left: -2.5rem;
  transition: all 0.5s ease-in-out;
}

.portfolio_card:nth-child(2n + 1):hover::before {
  height: 10%;
  width: 10.5%;
  border: 0.5px solid rgb(238, 212, 165);
  border-right: none;
  border-top: none;
}

.portfolio_card:nth-child(2n + 1)::after {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  border: 0.5px solid transparent;
  border-left: none;
  border-bottom: none;
  top: -2.5rem;
  right: -2.5rem;
  transition: all 0.5s ease-in-out;
}

.portfolio_card:nth-child(2n + 1):hover::after {
  height: 10%;
  width: 10.5%;
  border: 0.5px solid rgb(238, 212, 165);
  border-left: none;
  border-bottom: none;
}

.portfolio_card:nth-child(2n + 2)::before {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  border: 0.5px solid transparent;
  border-left: none;
  border-top: none;
  bottom: -2rem;
  right: -2rem;
  transition: all 0.5s ease-in-out;
}

.portfolio_card:nth-child(2n + 2):hover::before {
  height: 10%;
  width: 10.5%;
  border: 0.5px solid rgb(238, 212, 165);
  border-left: none;
  border-top: none;
}

.portfolio_card:nth-child(2n + 2)::after {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  border-right: none;
  border-bottom: none;
  transition: all 0.5s ease-in-out;
  border: 0.5px solid transparent;
  border-right: none;
  border-bottom: none;
  top: -2.5rem;
  left: -2.5rem;
  transition: all 0.5s ease-in-out;
}

.portfolio_card:nth-child(2n + 2):hover::after {
  height: 10%;
  width: 10.5%;
  border: 0.5px solid rgb(238, 212, 165);
  border-bottom: none;
  border-right: none;
}

@media (max-width: 1400px) {
  .portfolio {
    justify-content: center;
  }

  .portfolio_card:nth-child(2n + 1)::before {
    height: 10%;
    width: 10.5%;
    border: 0.5px solid rgb(238, 212, 165);
    border-right: none;
    border-top: none;
  }

  .portfolio_card:nth-child(2n + 1)::after {
    height: 10%;
    width: 10.5%;
    border: 0.5px solid rgb(238, 212, 165);
    border-left: none;
    border-bottom: none;
  }

  .portfolio_card:nth-child(2n + 2)::before {
    height: 10%;
    width: 10.5%;
    border: 0.5px solid rgb(238, 212, 165);
    border-left: none;
    border-top: none;
  }

  .portfolio_card:nth-child(2n + 2)::after {
    height: 10%;
    width: 10.5%;
    border: 0.5px solid rgb(238, 212, 165);
    border-bottom: none;
    border-right: none;
  }

  @media (max-width: 550px) {
    .portfolio_card:nth-child(2n + 1)::before {
      display: none;
    }

    .portfolio_card:nth-child(2n + 1)::after {
      display: none;
    }

    .portfolio_card:nth-child(2n + 2)::before {
      display: none;
    }

    .portfolio_card:nth-child(2n + 2)::after {
      display: none;
    }
  }
}

