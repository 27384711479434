
footer {
    height: 8rem;
    width: 100%;
    background-color: black;
    border-top: 3px solid wheat;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 2rem 0 4.4rem 0;
  }
  
  footer p {
    color: white;
    font-weight: 400;
    text-align: right;
    font-size: 1.6rem;
    position: relative;
    right: 1rem;
    /* text-shadow: 1px 0px 1px rgb(255, 250, 250); */
    font-family: "Quintessential", cursive;
    color: wheat;
  }
  
  @media screen and (min-width: 800px) {
    footer p {
      right: 3rem;
    }
  }